.jumbotron {
  background-color: $bg-color;
  border-radius: 12px;
  box-shadow: 0px 10px 30px 5px rgba(0, 0, 0, 0.4);
}

.hero-container {
  text-align: center;
  margin-top: 20px;
  padding-top: 50px;
  margin-bottom: 50px;
  background: radial-gradient(
    60% 100% at 50% 100%,
    rgba(142, 187, 255, 0.4) 17%,
    rgba(35, 39, 60, 0) 80%
  );

  header {
    color: $cream;

    h1 {
      font-size: 52px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    label {
      font-size: 20px;
      font-weight: 200;
    }
    &.container {
      padding-bottom: 30px;
    }
  }

  .hero-image {
    max-width: 650px;
    aspect-ratio: 2;
    margin-bottom: -3px;
  }

  @media screen and (max-width: 768px) {
    header {
      h1 {
        font-size: 50px;
      }

      label {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    padding-top: 80px;
    header {
      h1 {
        font-size: 40px;
      }

      label {
        font-size: 15px;
      }
    }
  }

  @media screen and (max-width: 320px) {
    header {
      h1 {
        font-size: 30px;
      }

      label {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-width: 1728px) {
    background: radial-gradient(
      45% 90% at 50% 100%,
      rgba(142, 187, 255, 0.4) 17%,
      rgba(35, 39, 60, 0) 80%
    );
  }

  @media screen and (max-width: 1464px) {
    background: radial-gradient(
      55% 90% at 50% 100%,
      rgba(142, 187, 255, 0.4) 17%,
      rgba(35, 39, 60, 0) 80%
    );
  }

  @media screen and (max-width: 1232px) {
    background: radial-gradient(
      60% 90% at 50% 100%,
      rgba(142, 187, 255, 0.4) 17%,
      rgba(35, 39, 60, 0) 80%
    );
  }

  @media screen and (max-width: 1095px) {
    background: radial-gradient(
      70% 90% at 50% 100%,
      rgba(142, 187, 255, 0.4) 17%,
      rgba(35, 39, 60, 0) 80%
    );
  }

  @media screen and (max-width: 568px) {
    background: radial-gradient(
      70% 80% at 50% 100%,
      rgba(142, 187, 255, 0.4) 17%,
      rgba(35, 39, 60, 0) 80%
    );
  }

  @media screen and (max-width: 478px) {
    background: radial-gradient(
      70% 65% at 50% 100%,
      rgba(142, 187, 255, 0.4) 17%,
      rgba(35, 39, 60, 0) 80%
    );
  }
}
