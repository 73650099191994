body {
  margin: 0;
  font-family: Roboto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@import "colors";
@import "landingscreen";
@import "components";
@import "utils";
@import "songscreens";
@import "homescreen";

.ant-layout-header.header {
  position: sticky;
  top: 0;
  z-index: 2;
  .ant-avatar {
    background-color: $light-blue;
    color: $gray-blue;
    font-weight: 500;
  }
}

.ant-menu-item-icon {
  vertical-align: 0 !important;
}

.demo-logo-vertical {
  color: white;
  text-align: center;
  font-size: 20px;
}

.main-container {
  padding: 20px;
  min-height: 100vh;
}

.container {
  max-width: 1240px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 100px;
}

.table-actions {
  button {
    margin: 0 5px;

    &.delete-action {
      color: red;
    }
  }
}

.ant-table {
  border-radius: 8px !important;
}
