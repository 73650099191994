.page-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  h2 {
    margin-bottom: 0;
  }
  padding-bottom: 15px;
  margin-bottom: 40px;
}

.contributor-table-name {
  .ant-avatar {
    margin-right: 15px;
  }
}

.upload-singlefile-container {
  height: 100px;
  padding: 10px;
  border-radius: 12px;
  max-height: 100px;

  .upload-form-data {
    width: 100%;
  }
}

.transpose-map {
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    label {
      width: 35px;
      height: 35px;
      padding: 0;
      text-align: center;
      border-radius: 6px;
      border-width: 1px;
      &::before {
        display: none;
      }
    }
  }
}
