.landing-page-container {
  min-height: 100vh;

  .login-form {
    max-width: 400px;
    margin: 80px auto;

    h4 {
      margin-top: 0;
      text-align: center;
    }
  }
}
