// margin-top
.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

// margin-bottom
.mb-2 {
  margin-bottom: 3em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-0 {
  margin-bottom: 0 !important;
}

// padding-bottom
.pb-1 {
  padding-bottom: 1rem;
}

//padding-right
.pr-1 {
  padding-right: 1em;
}
