.chords-lyrics-preview {
  padding: 8px 10px;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  width: 100%;
  height: 100%;

  &.selected {
    border-color: #1677ff;
    border-width: 2px;
  }
}

.instrument-config-card {
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-bottom: 20px;

  &.selected {
    border: 2px solid #1677ff;
  }
}

.outsourced-songs-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  h3 {
    margin: 0;
  }
}
